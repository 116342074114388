<template>
  <div
    class="flex justify-center items-center cursor-pointer flex-1 w-full h-8 mb-1"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="w-full mx-2 cursor-pointer z-10">
      <div class="w-full h-2 cursor-pointer relative overflow-hidden">
        <div class="w-full h-full z-50 absolute opacity-0" />

        <div class="bg-gray-200 w-full h-full absolute rounded-full">
          <div
            class="bg-purple-700 h-full rounded-full"
            :style="progressBarStyle"
          />
        </div>

        <div
          class="absolute bg-white rounded-full h-2 w-2 -ml-2 shadow"
          :class="{ 'opacity-1' : pillVisible, 'opacity-0': !pillVisible }"
          :style="pillStyle"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewerProgressBar',

  props: ['progressPc', 'duration'],

  data () {
    return {
      basePc: null,

      pillVisible: true
    }
  },

  computed: {

    progressBarStyle () {
      return 'width: ' + this.progressPc + '%'
    },

    pillStyle () {
      return 'left: ' + this.progressPc + '%;  transition: opacity 0.25s ease-in-out;'
    }

  },

  methods: {

    onMouseLeave () {
      this.pillVisible = false
    },
    onMouseEnter () {
      this.pillVisible = true
    },

    onMouseDown (event) {
      event.stopPropagation()

      let offset = event.offsetX
      let width = event.target.clientWidth
      let pc = offset / width

      let val = this.duration * pc

      this.$emit('jump-to-time', val)

      let cumuliative = 0
      this.basePc = pc

      this.$emit('mouse-down', (movementX) => {
        this.pillVisible = true
        let width = event.target.clientWidth
        let scaled = movementX / width
        cumuliative = cumuliative + scaled

        let newpc = this.basePc + cumuliative
        if (newpc < 0) newpc = 0
        if (newpc > 100) newpc = 100

        let time = this.duration * newpc
        time = time.toFixed(2)

        this.$emit('jump-to-time', time)
      })
    },
    onMouseUp (event) {
      event.stopPropagation()
      this.$emit('mouse-down', null)
      this.pillVisible = false
    }
  }
}
</script>
